import './src/styles/globals.css'
import './src/styles/custom.css'
import './src/styles/plugins.css'

import React from 'react'
import M1FStateProvider from './src/Context'

export const wrapRootElement = ({ element }) => (
    <M1FStateProvider>
        {element}
    </M1FStateProvider>
)
